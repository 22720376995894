import React from 'react'
import { Link } from 'gatsby'
import {getConversion} from '../../api/images'

const ContactInfo = ({ directory, contact }) => {
    const { first_name, last_name, media } = contact
    const { directories } = directory

    var name = last_name ? last_name + ', ' + first_name : first_name

    return (
        <div className='col-lg-4 col-sm-6'>
            <Link to={'/contact?slug=' + contact.slug}>
                <div className='single-contact-item-box'>
                    <div className='image'>
                        {media[0] ?
                            <img src={getConversion(media[0])} alt={name} width={200} />
                            :
                            null
                        }
                    </div>
                    <div className='content'>
                        <h3>{name}</h3>
                        {directories.length > 0 ?
                            <span>{contact.directory.name}</span>
                            : null
                        }
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ContactInfo